/* Ensure the background covers entire height */
html, body, #root {
  height: 100%;
  margin: 0;
  background-color: #f2f2f2; /* Replace with your desired background color */
}

/* Styling for the informational text */
.info-section {
  text-align: center;
  margin-bottom: 20px;
}

.slogan {
  font-style: italic;
  color: #007BFF; /* Bright blue for the slogan */
}

.made-in {
  font-weight: bold;
  color: #595959; /* Medium-dark gray for secondary text */
}

.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f2f2f2;
}

.brand-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.brand-logo {
  width: 50px; /* Adjust as needed */
  height: auto;
  margin-right: 10px;
}

.brand-title {
  font-size: 24px;
  color: #333;
}

.upload-form {
  width: 100%; /* Full width on mobile */
  max-width: 300px; /* Fixed max width for larger screens */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  margin-bottom: 20px;
}

/* ... Rest of the CSS remains the same ... */

@media (max-width: 600px) {
  .upload-form {
    padding: 15px;
  }

  .brand-title {
    font-size: 20px;
  }

  .file-input-label {
    font-size: 14px;
  }

  .submit-button, .remove-button {
    font-size: 14px;
  }
}

.image-preview img {
  max-width: 80px; /* Smaller images on mobile */
}

.upload-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  width: 300px; /* Fixed width for form */
}

.file-input-label {
  margin-bottom: 15px;
  font-size: 16px;
  color: #333;
}

.file-input {
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  color: transparent;
}

.submit-button {
  width: 100%;
  padding: 12px;
  margin-top: 10px;
  background-color: #007bff;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  transition: background-color 0.2s;
}

.submit-button:hover {
  background-color: #0056b3;
}

.image-preview-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 20px;
  justify-content: center;
}

.image-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
  background-color: #fff;
}

.image-preview img {
  max-width: 100px;
  height: auto;
  border-radius: 5px;
  margin-bottom: 5px;
}

.remove-button {
  border: none;
  background-color: #ff4d4f;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.remove-button:hover {
  background-color: #ff3333;
}

.loader {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  margin: 20px auto; /* Centering the loader */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.submit-button.disabled {
  background-color: #ccc;
  cursor: default;
}

.submit-button.disabled:hover {
  background-color: #ccc;
}

/* ... rest of your CSS ... */
.footer-icons {
  text-align: center;
  padding: 20px;
  margin-top: 20px;
}

.footer-icons a {
  margin: 0 10px;
}

.footer-icons img {
  height: 30px; /* Adjust size as needed */
}

.privacy {
  cursor: pointer;
}

.privacy-policy-agreement {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  color: #333;
}

.privacy-policy-agreement a {
  color: #007bff;
  text-decoration: underline;
}

.privacy-policy-agreement a:hover {
  color: #0056b3;
  text-decoration: none;
}
