.modal {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.6);
    
    align-items: center;
    justify-content: center;
}

.modal.show {
    display: flex;
}

.modal-content {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    width: 90%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 250px; /* Reduced height */
    max-height: 400px; /* Limit max height */
}

.close {
    color: #aaa;
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #333;
    cursor: pointer;
}

h2 {
    font-size: 1.5em;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
}

.price-info {
    font-size: 1.2em;
    color: #555;
    text-align: center;
    margin-bottom: 20px;
}

.card-details {
    margin-bottom: 20px;
}

.StripeElement {
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
}

.error {
    color: red;
    margin-top: 10px;
    text-align: center;
}

.confirm-btn {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    width: 100%;
}

.confirm-btn:hover {
    background-color: #45a049;
}
