.feedback-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.title {
    color: #333;
    text-align: center;
    margin-bottom: 30px;
}

.category-card {
    background-color: #fff;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    transition: transform 0.2s;
}

.category-card:hover {
    transform: translateY(-5px);
}

.category-title {
    color: #2a2a2a;
    margin-bottom: 10px;
}

.rating {
    height: 20px;
    background-color: #ddd;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 10px;
}

.rating-bar {
    height: 100%;
    background-color: #4caf50; /* Change as per your color theme */
    border-radius: 10px;
}

.category-details {
    color: #555;
}

.advice-section {
    padding: 20px;
    background-color: #e3f2fd;
    border-radius: 5px;
    margin-top: 30px;
}

.advice-title {
    color: #333;
    margin-bottom: 15px;
}

.advice-content {
    color: #555;
}

.no-feedback {
    text-align: center;
    color: #999;
    font-size: 18px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.title {
    margin-right: 15px; /* Space between title and logo */
}

.brand-logo {
    height: 50px; /* Adjust the size as needed */
}